import {Injectable} from '@angular/core';
import {ApiService} from '../../api.service';

@Injectable()
export class WorkReportService {
  private apiPrefix = '/client/daily-work-report';

  constructor(
    private api: ApiService,
  ) {
  }

  getDailyWorkReport(payload: { start: Date; end: Date; }) {
    return this.api.post(`${this.apiPrefix}`, payload);
  }

  setWorkReport(payload: object) {
    return this.api.post(`${this.apiPrefix}/set`, payload);
  }
}
